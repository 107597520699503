<template>
    <div class="content">
        <!-- 搜索栏 -->
        <div class="el-row">
            <el-input placeholder="请输入推销员手机" class="inputBox mg-r-20" v-model="searchObj.phone"></el-input>
            <el-select
                    v-model="searchObj.sourceIds"
                    multiple
                    placeholder="请选择来源"
                    class="mg-r-20">
                <el-option
                        v-for="item in sourceOptions"
                        :key="item.id"
                        :label="item.sourceName"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" @click="getSalesmanByPage">查询</el-button>
            <el-button type="success" @click="addSalesmanDialog">新增</el-button>
            <el-button type="default" @click="resetSearch">重置</el-button>
        </div>
        <!-- 推销员列表 -->
        <div class="el-row mg-t-20">
            <el-table
                    :data="tableData.list"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="id"
                        label="id"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="推销员姓名"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="联系电话">
                </el-table-column>
                <el-table-column
                        prop="sourceName"
                        label="来源">
                </el-table-column>
                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <div class="flx-row ali-c">
                            <span class="tip-text-info ml-15"
                                  @click="modifySalesmanDialog(scope.row.id, scope.row.name, scope.row.phone, scope.row.sourceId)">
                                修改
                            </span>
                            <span class="tip-text-delete ml-15" @click="deleteConfirm(scope.row.id)">
                                删除
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--翻页插件-->
        <div class="el-row mg-t-20">
            <el-pagination
                    align="center"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="tableData.pageNum"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="tableData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData.total">
            </el-pagination>
        </div>
        <!--弹窗对话框-->
        <el-dialog
                :title="dialog.title"
                :visible.sync="dialog.visible"
                width="50%"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :before-close="handleDialogClose">
            <!--添加推销员-->
            <div class="el-row" v-if="dialog.salesman.visible">
                <el-form :model="dialog.salesman" :rules="dialog.salesman.rules" ref="dialog.salesman">
                    <el-form-item label="姓名" prop="name">
                        <el-input class="inputBox" v-model="dialog.salesman.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机" prop="phone">
                        <el-input class="inputBox" v-model="dialog.salesman.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="来源" prop="sourceId">
                        <el-select
                            v-model="dialog.salesman.sourceId"
                            placeholder="请选择来源"
                            clearable
                            class="inputBox mg-r-20">
                            <el-option
                                    v-for="item in sourceOptions"
                                    :key="item.id"
                                    :label="item.sourceName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="handleDialogClose">取 消</el-button>
            <el-button type="primary" @click="submitAddOrModifySalesman">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import { getSalesman, addSalesman, modifySalesman, deleteSalesman, getAllSalesmanSource } from "@/api/maketing/internal_purchase";
    export default {
        name: "salesman_managed",
        data() {
            return {
                searchObj: {
                    phone: null,
                    sourceIds: null,
                },
                sourceOptions: [],
                tableData: {
                    list: [],
                    pageSize: 10
                },
                dialog: {
                    title: '提示',
                    visible: false,
                    salesman: {
                        id: null,
                        name: null,
                        phone: null,
                        sourceId: null,
                        visible: false,
                        rules: {
                            name: [
                                {required: true, message: "分享图片不能为空", trigger: "blur"}
                            ],
                            phone: [
                                {required: true, message: "活动名称不能为空", trigger: "blur"}
                            ],
                            sourceId: [
                                {required: true, message: "来源不能为空", trigger: "blur"}
                            ],
                        }
                    }
                }
            }
        },
        mounted() {
            this.initialTable();
            this.getStores();
        },
        methods: {
            //查询初始化
            initialTable() {
                let param = {
                    phone: null,
                    sourceIds: null,
                    pageSize: this.tableData.pageSize,
                    pageNum: 1
                }
                getSalesman(param).then(res => {
                    this.tableData = res.data.body
                })
            },
            //每页显示行数
            handleSizeChange(val) {
                this.tableData.pageSize = val
                this.getSalesmanByPage()
            },
            //翻页
            handleCurrentChange(val) {
                this.tableData.pageNum = val
                this.getSalesmanByPage()
            },
            //条件查询
            getSalesmanByPage() {
                let param = {
                    phone: this.searchObj.phone,
                    sourceIds: this.searchObj.sourceIds,
                    pageNum: this.tableData.pageNum,
                    pageSize: this.tableData.pageSize
                }
                getSalesman(param).then(res => {
                    this.tableData = res.data.body
                })
            },
            //重置查询
            resetSearch() {
                this.searchObj.phone = null
                this.searchObj.sourceIds = null
                this.initialTable()
            },
            //查询来源
            getStores() {
                getAllSalesmanSource().then((res) => {
                    this.sourceOptions = res.data.body;
                });
            },
            //关闭对话框前的操作
            handleDialogClose() {
                this.dialog.salesman.visible = false
                this.dialog.visible = false
                this.dialog.salesman.id = null
                this.dialog.salesman.name = null
                this.dialog.salesman.phone = null
                this.dialog.salesman.sourceId = null
            },
            //点击添加按钮
            addSalesmanDialog() {
                this.dialog.title = '添加推销员'
                this.dialog.visible = true
                this.dialog.salesman.visible = true
            },
            //提交添加推销员之前的表单校验
            submitAddOrModifySalesman() {
                if(this.dialog.salesman.sourceId == null) {
                    this.$message({
                        message: "表单信息不完整（有误）",
                        type: "warning"
                    });
                    return
                }
                this.$refs["dialog.salesman"].validate(valid => {
                    if (!valid) {
                        this.$message({
                            message: "表单信息不完整（有误）",
                            type: "warning"
                        });
                        return;
                    }
                    //id为空是新增
                    if (this.dialog.salesman.id == null) {
                        this.submitAddSalesmanForm();
                    } else { // id不为空是修改
                        this.submitModifySalesmanForm();
                    }

                });
            },
            //表单校验完后调用这方法提交请求
            submitAddSalesmanForm() {
                let param = {
                    phone: this.dialog.salesman.phone,
                    name: this.dialog.salesman.name,
                    sourceId: this.dialog.salesman.sourceId
                }
                addSalesman(param).then(res => {
                    this.handleDialogClose()
                    this.getSalesmanByPage()
                    if(res.data.code === 200) {
                        this.$message.success('添加成功')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            modifySalesmanDialog(id, name, phone, sourceId) {
                this.dialog.title = '修改推销员'
                this.dialog.visible = true
                this.dialog.salesman.visible = true
                this.dialog.salesman.id = id
                this.dialog.salesman.name = name
                this.dialog.salesman.phone = phone
                this.dialog.salesman.sourceId = sourceId
            },
            submitModifySalesmanForm() {
                let param = {
                    id: this.dialog.salesman.id,
                    phone: this.dialog.salesman.phone,
                    name: this.dialog.salesman.name,
                    sourceId: this.dialog.salesman.sourceId
                }
                modifySalesman(param).then(res => {
                    this.handleDialogClose()
                    this.getSalesmanByPage()
                    if(res.data.code === 200) {
                        this.$message.success('修改成功')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            deleteConfirm(id) {
                this.$confirm('确定删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteSalesman(id).then(res =>{
                        this.getSalesmanByPage()
                        this.initialTable()
                        if(res.data.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {

                });
            },
        }
    }
</script>

<style scoped>
    .content {
        padding: 20px
    }

    .inputBox {
        width: 200px;
    }

    .mg-r-20 {
        margin-right: 20px;
    }

    .mg-t-20 {
        margin-top: 20px;
    }
</style>
