import request from '@/utils/request'

export function getSalesman(data) {
    return request({
        url: '/small-inteface/lrSalesman/list',
        method: 'post',
        data
    })
}

export function addSalesman(data) {
    return request({
        url: '/small-inteface/lrSalesman/add',
        method: 'post',
        data
    })
}

export  function modifySalesman(data) {
    return request({
        url: '/small-inteface/lrSalesman/modify',
        method: 'put',
        data
    })
}

export  function deleteSalesman(data) {
    return request({
        url: '/small-inteface/lrSalesman/' + data,
        method: 'delete'
    })
}

export  function getInternalPurchaseOrders(data) {
    return request({
        url: '/order-service/internalPurchaseOrder/backstage',
        method: 'post',
        data
    })
}

export  function getAllSalesmanSource(data) {
    return request({
        url: '/small-inteface/lrSalesmanSource/list',
        method: 'get'
    })
}

export function salesmanSourceBackstage(data) {
    return request({
        url: '/small-inteface/lrSalesmanSource/backstage',
        method: 'post',
        data
    })
}

export function addSalesmanSource(data) {
    return request({
        url: '/small-inteface/lrSalesmanSource/add',
        method: 'post',
        data
    })
}

export function updateSalesmanSource(data) {
    return request({
        url: '/small-inteface/lrSalesmanSource/update',
        method: 'put',
        data
    })
}

export function deleteSalesmanSource(data) {
    return request({
        url: '/small-inteface/lrSalesmanSource/delete',
        method: 'delete',
        data
    })
}

export  function getInternalPurchaseOrdersOsm(data) {
    return request({
        url: '/order-service/internalPurchaseOrder/backstageOsm',
        method: 'post',
        data
    })
}